/* eslint-disable no-debugger */
import { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { AUTH } from '@constants/routes';
import { getLocalStorageItem } from '@utils/storage';
import { SideNavBar, Spinner, Topbar } from '@components';
import { setCurrentUser, setRole } from '@features/authentication/userSlice';
import { useGetUserDetailsQuery } from '@features/authentication/queries/authQueries';
import { RoleType } from '@types';

const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data,
    isError: userError,
    isLoading
  } = useGetUserDetailsQuery(null, {
    refetchOnMountOrArgChange: true
  });

  const token = getLocalStorageItem('token');

  useEffect(() => {
    if (!token) navigate(AUTH.LOGIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.data) {
      dispatch(setCurrentUser({ user: data?.data }));
      if (data?.data?.accessLevels[0]?.name === 'Student')
        dispatch(setRole({ role: RoleType.STUDENT }));
      else if (data?.data?.accessLevels[0]?.name === 'Parent')
        dispatch(setRole({ role: RoleType.PARENT }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (userError) navigate(AUTH.LOGIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userError]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div className="size-full">
        <div className="hidden sm:block">
          <SideNavBar userDetails={data?.data} />
        </div>
        <div className="sm:hidden">
          <Topbar />
        </div>
        <div className="h-[calc(100%-60px)] overflow-y-auto bg-yellow sm:h-screen">
          <div className="wrapper custom-scroll-bar" id="main-layout-wrapper">
            <Suspense
              fallback={
                <div className="absolute top-1/2 flex size-full -translate-y-1/2 items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

export const AUTH = {
  LOGIN: '/login',
  MASCOT_SELECTION: '/select-mascot/:subjectId',
  SUBJECT_SELECTION: '/select-subject'
};

export const HOME = {
  HOME: '/'
};

export const HOMEWORKS = {
  HOMEWORKSLIST: '/homeworks',
  SUBMISSION: '/homeworks/:homeworkId',
  REVIEW: '/homeworks/:homeworkId/review',
  REVIEW_BY_STUDENT_ID: '/homeworks/:homeworkId/student/:studentId/review',
  STUDENT_REPORT: '/homeworks/report/:studentId'
};

export const DASHBOARD = {
  DASHBOARD: '/dashboard'
};

export const NOTIFICATIONS = {
  NOTIFICATIONS: '/notifications'
};

export const LEADERBOARD = {
  LEADERBOARD: '/leaderboard'
};

export const SETTINGS = {
  SETTINGS: '/settings'
};

export const GAMIFICATION = {
  AVATAR_DETAILS: '/avatar-details'
};

export const ERROR = {
  ERROR: '/error/:errorStatus',
  PAGE_404: '/error/404'
};

import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { GAMIFICATION } from '@constants/routes';

const AvatarDetails = lazy(
  () => import('@features/gamification/pages/avatar-details/AvatarDetails')
);

export const GamificationRoutes: RouteObject[] = [
  {
    path: GAMIFICATION.AVATAR_DETAILS,
    element: <AvatarDetails />
  }
];

export default GamificationRoutes;

interface ProgressBarProps {
  height: number;
  progress: number;
  primaryColor: string;
  bgColor: string;
  wrapperStyle?: string;
  isIndicator?: boolean;
  indicatorColor?: string;
  progressIcon?: string;
  secondaryColor?: string | null;
  iconStyle?: string;
  progressBarStyle?: 'default' | 'gradient' | 'patterned';
}

const ProgressBar = ({
  height,
  progress,
  primaryColor,
  bgColor,
  wrapperStyle,
  isIndicator = false,
  indicatorColor,
  progressIcon,
  secondaryColor,
  iconStyle,
  progressBarStyle = 'default'
}: ProgressBarProps) => {
  const selectProgressBarStyle = () => {
    switch (progressBarStyle) {
      case 'gradient':
        return `linear-gradient(to right,${primaryColor},${secondaryColor})`;
      case 'patterned':
        return `repeating-linear-gradient(
        45deg,
        ${primaryColor},
        ${primaryColor} 10px,
        ${secondaryColor} 10px,
        ${secondaryColor} 20px
      )`;
      default:
        return primaryColor;
    }
  };

  const styles = {
    progressWrapperStyle: {
      height,
      backgroundColor: bgColor
    },
    progressBarStyle: {
      width: `${progress}%`,
      background: selectProgressBarStyle()
    }
  };

  return (
    <div
      style={styles.progressWrapperStyle}
      className={`relative w-full rounded-full ${wrapperStyle}`}
    >
      <div
        style={styles.progressBarStyle}
        className="flex h-full items-center justify-end rounded-full"
      >
        {progress > 0 && progressIcon && (
          <img
            src={progressIcon}
            className={`absolute size-12 ${
              progress < 20 ? '-mr-9' : '-mr-2'
            } ${iconStyle}`}
            alt="Mascot-Icon"
          />
        )}
      </div>

      {isIndicator && (
        <div
          className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${
            indicatorColor || `${progress < 30 ? 'text-primary' : 'text-theme'}`
          }`}
        >{`${progress}% success`}</div>
      )}
    </div>
  );
};

export default ProgressBar;
